import React, { useEffect, useState, useContext } from 'react';
import PositionCardMLM from './../components/PositionCardMLM';
import BottomSheetPortfolio from './../components/BottomSheetPortfolio';
import { WebSocketContext } from "../WebSocketContext";
import config from '../config'; // Assuming you have a config file with API_BASE_URL
import '../Portfolio.css'; // Assuming you will style the portfolio page
import { Refresh } from '@mui/icons-material';

const MLMPortfolio = () => {
  //const [positions, setPositions] = useState([]);
  //const [userDetails, setUserDetails] = useState({});
  //const { stocks, lastUpdateTime } = useContext(WebSocketContext);
  
  const { mergerIDsEx, performance, userScripts=[], stocks, userDetails, refreshTradesAndPortfolio } = useContext(WebSocketContext);
  const [showBottomSheet, setShowBottomSheet] = useState(false);
  const [selectedStock, setSelectedStock] = useState(null);
  const [isDetailsExpanded, setIsDetailsExpanded] = useState(false);
  const [positions, setPositions] = useState([]);
  let oldRates = [];
  let userScriptsTemp = [];
  const user_id = Number(localStorage.getItem('userId'));
  const parent_id = Number(localStorage.getItem('parentId'));
  const [isRefreshing, setRefreshing] = useState(false);
  const user_type = "brokerMLM";
//   const parent_id = userDetails.parentId;

  // Function to fetch portfolio from API
  const fetchPortfolio = async (user_id) => {
    
    try {
        // console.log("Hello");
        // console.log(userDetails);
        // console.log("Hi: "+parent_id);
      const response = await fetch(`${config.API_BASE_URL}portfolioOrdersMasterMLM`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_id, user_type, parent_id }),
      });
      const data = await response.json();
      // /console.log("Data Received : "+data[0]['orderId']);

      const instrumentTokens = data.map(script => script.instrument_token);
    //   console.log(instrumentTokens);
      //setStockids(instrumentTokens);
      //wsServiceRef.current.mergerIDs(instrumentTokens);
      mergerIDsEx(instrumentTokens);
      //reconnectWebSocket();

      
    //   const sortedData = data.sort((a, b) => b.orderId - a.orderId);

      // Parse the input JSON response
      const orders = data;//JSON.parse(data);

      // Process orders to calculate pnl and brokers
      const processedOrders = orders.map(order => {
          let currentPnl = 0;

          if (order.buy_sell === 1) {
              if (order.buyPrice === 0) {
                  currentPnl = 0;
              } else {
                  currentPnl = (order.buyPrice * order.orderQuantity) - 
                               (order.orderPrice * order.orderQuantity);
              }
          } else {
              if (order.sellPrice === 0) {
                  currentPnl = 0;
              } else {
                  currentPnl = (order.orderPrice * order.orderQuantity) - 
                               (order.sellPrice * order.orderQuantity);
              }
          }

          // Add pnl and pnl_for_total
          order.pnl = currentPnl;
          order.pnl_for_total = currentPnl - order.brokerage;

          // Decode broker_brokerage and extract brokers
          const brokerBrokerage = JSON.parse(order.broker_brokerage);
          const brokers = Object.keys(brokerBrokerage);
          order.brokers = brokers.join(",");

          return order;
      });

      // Sort orders in descending order of orderId
      const sortedOrders = processedOrders.sort((a, b) => b.orderId - a.orderId);

      setPositions(sortedOrders);

    //   setPortfolio(sortedData); // Assuming data is an array of portfolio positions
      setRefreshing(false);
    } catch (error) {
      console.error('Error fetching portfolio:', error);
      setRefreshing(false);
    }
  };

  const toggleDepth = () => {
    setIsDetailsExpanded(!isDetailsExpanded);
  };

  const handleCardClick = (position) => {
    setSelectedStock(position);
    // if(stock.exchange=="OPTSTK"){
    //   stock.intraday = userDetails.OPTSTK_intraday;
    // } else if(stock.exchange=="FUTSTK"){
    //   stock.intraday = userDetails.FUTSTK_intraday;
    // } else if(stock.exchange=="FUTCOM"){
    //   stock.intraday = userDetails.FUTCOM_intraday;
    // }
    setShowBottomSheet(true);
  };

  const handleCloseSheet = () => {
    setShowBottomSheet(false);
    setSelectedStock(null);
    // alert("closed"+showBottomSheet);
  };

  // Function to get the latest stock data for the selected stock
  const getLatestStockData = (stock) => {
    return stocks[stock.instrument_token] || {};
  };

  useEffect(() => {
    fetchPortfolio(user_id);
  }, []);

  // Effect to update the selected stock data in the bottom sheet whenever stocks context updates
  useEffect(() => {
    
    if (selectedStock) {
      const latestStockData = getLatestStockData(selectedStock);
      setSelectedStock((prevStock) => ({
        ...prevStock,
        live: latestStockData,
      }));
    }
  }, [stocks, selectedStock]);

  // Calculate total PnL
  const calculateTotalPnL = () => {
    return positions.reduce((total, position) => {
      //console.log("calc pnls : ",position);
      const stock = stocks[position.instrument_token] || {};
      const pnl = Number(calculatePnL(position, stock));
      var t =Number(Number(total) + pnl - Number(position.brokerage)).toFixed(2); 
      
      return t;
    }, 0);
  };

  const calculateBookedPnL = () => {

    if (!Array.isArray(performance)) {
      performance = [performance]; // Ensure it's an array
    }

    return performance.reduce((total, position) => {
      //const stock = stocks[position.instrument_token] || {};
      //console.log("calc pnl", position);
      const pnl = Number(position.closing_order.pnl);
      var t =Number(Number(total) + pnl - Number(position.brokerage) - Number(position.closing_order.brokerage)).toFixed(2); 
      
      return t;
    }, 0);
  };

  // const calculateTotalPnl = () => {
  //   return Number( Number(calculatePnL()) + Number(calculateBookedPnL()) ).toFixed(2);
  // };

  // Function to calculate PnL for each position
  const calculatePnL = (position, stock) => {
    if (position.buy_sell === "1") {
      if(stock.Bid==undefined) stock.Bid = position.buyPrice;
      if (stock.Bid === undefined){ 
        //console.log(stock.stockName, stock.Bid);
        return 0;
      }
      else
      { 
        //console.log(stock.stockName, stock.Bid);
        return (stock.Bid * position.orderQuantity) - (position.orderPrice * position.orderQuantity);
      }
    } 
    else 
    {
      if(stock.Ask==undefined) stock.Ask = position.sellPrice;
      if (stock.Ask === undefined){ 
        //console.log(stock.stockName, stock.Ask);
        return 0;
      }
      else
      {
          //console.log(stock.stockName, stock.Ask);
         return (position.orderPrice * position.orderQuantity) - (stock.Ask * position.orderQuantity);
      }
    }
  };

  const handleRefresh = () => {
    refreshTradesAndPortfolio("both");
  };

  return (
    <div className="portfolio-page">
      {/* <div className="total-pnl">
        <h2>Total PnL: {calculateTotalPnL()}</h2>
      </div> */}
      <button className="refresh-button" onClick={handleRefresh}><Refresh /></button>
      {/* <div className="pnl-display">
        <span className="pnl-title">Total P&L </span>
        <span className={`pnl-value ${calculateTotalPnL() < 0 ? 'negative' : 'positive'}`}>
        ₹{calculateTotalPnL()}
        </span>
      </div> */}

      <div  className="pnl-display" onClick={toggleDepth} style={{display:'flex', padding:'0px'}}>
        <div className="pnl-display" style={{display:'block', margin:'0px'}}>
          <span className="pnl-title">Current P&L </span>
          <p className={`pnl-value ${calculateTotalPnL() < 0 ? 'negative' : 'positive'}`}>
          ₹{calculateTotalPnL()}
          </p>
        </div>
        <div className="pnl-display" style={{display:'block', margin:'0px', textAlign:'center'}}>
          <span className="pnl-title">Booked P&L </span>
          <p className={`pnl-value ${calculateBookedPnL() < 0 ? 'negative' : 'positive'}`}>
          ₹{calculateBookedPnL()}
          </p>
        </div>
        <div className="pnl-display" style={{display:'block', margin:'0px', textAlign:'right'}}>
          <span className="pnl-title">Net P&L </span>
          <p className={`pnl-value ${ (Number(calculateBookedPnL())+Number(calculateTotalPnL())) < 0 ? 'negative' : 'positive'}`}>
          ₹{ Number(Number(calculateBookedPnL()) +Number(calculateTotalPnL())).toFixed(2)}
          </p>
        </div>
        
      </div>

      {positions.length > 0 && Object.keys(userDetails).length > 0 ? (
        positions.map(position => {
          const stock = stocks[position.instrument_token] || {};
          //console.log(position);
          return (
            <PositionCardMLM 
              key={position.orderId} 
              stock={stock} 
              userDetails={userDetails} 
              position={position} 
              onCardClick={handleCardClick}
              isMLMClient = {true}
            />
          );
        })
      ) : (
        <p>Loading positions...</p>
      )}
      {selectedStock && <BottomSheetPortfolio isOpen={showBottomSheet} onClose={handleCloseSheet} userDetails={userDetails} position={selectedStock} />}
    </div>
  );
}

export default MLMPortfolio;

