import React from 'react';
import './../PositionCard.css'; // Assuming you will style the component similarly to TradeCard
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PositionCardMLM = ({ stock, position, userDetails, onCardClick }) => {
    
    const handleClick = () => {
        position['live'] = stock;
        onCardClick(position);
      };

    // Calculate PnL function
    const calculatePnL = () => {
        if (position.buy_sell === "1") {
            if(stock.Bid==undefined) stock.Bid = position.buyPrice;
            if (stock.Bid === 0)
                return 0;
            else
                return Number((stock.Bid * position.orderQuantity) - (position.orderPrice * position.orderQuantity) - position.brokerage).toFixed(2);
        } else {
            if(stock.Ask==undefined) stock.Ask = position.sellPrice;
            if (stock.Ask === 0)
                return 0;
            else
                return Number((position.orderPrice * position.orderQuantity) - (stock.Ask * position.orderQuantity) - position.brokerage).toFixed(2);
        }
    };

    // Define calculateValue function
    const calculateValue = (order) => {
        if (order.exchange === 'OPTSTK' && order.buy_sell === "2") {
            return (order.orderLotQuantity * userDetails.OPTSTK_balanceCF).toFixed(2);
        }
        return ((order.orderQuantity * order.orderPrice) / userDetails[`${order.exchange}_delivery`]).toFixed(2);
    };

    var buy_sell_text = position.buy_sell === "1" ? "BUY" : "SELL";
    const orderValue = calculateValue(position);
    const quantityColor = position.buy_sell === '1' ? 'green' : 'red';
    const pnlColor = calculatePnL() > 0 ? "green" : "red";

    const showToast = (message) => {
        toast.warning(message, {
            position:'top-right',
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    const formatDate = (dateString) => {
        // const date = new Date(dateString);
        // const day = date.getDate();
        // const month = date.toLocaleString('en-US', { month: 'long' });
        // return `${day} ${month} ${date.getFullYear()}`;
        const date = new Date(dateString);
        const day = date.getDate();
        const year = date.getFullYear();
        const month = date.toLocaleString('en-US', { month: 'short' });
        return `${day}-${month}-${year}`;
      };

    return (
        <div className="position-card" onClick={handleClick}>
            <ToastContainer />
            <div className="d-flex justify-content-between align-items-center">
                <div>
                    <h4 className="position-scriptName">
                        {position.stockName} &nbsp;
                        <span className='position-autocut'>
                            {formatDate(position.autoCutDate)}
                        </span>
                    </h4>
                </div>
                <div className="text-end">
                    <p className={`position-pnl ${pnlColor}`}>₹{ calculatePnL() }</p>
                </div>
            </div>
            <div className="d-flex justify-content-between align-items-center">
                <div>
                    {/* <p className='position-dateTime'>Autocut: {new Date(position.autoCutDate).toLocaleString()}</p> */}
                    <span className={`position-quantity ${quantityColor}`}>
                        {position.buy_sell === '1' ? `Buy QTY:${position.orderQuantity}` : `Sell QTY:-${position.orderQuantity}`}
                    </span>
                    <span className="position-market">
                        {position.orderType === '1' ? `MARKET` : `LIMIT`}
                    </span>
                </div>
                <div className="text-end">
                    <p className='position-pnl'>{ `${Number(position.orderPrice).toFixed(2)} >> ${position.buy_sell === '1' ? Number(stock.Bid!=undefined ? stock.Bid : position.buyPrice).toFixed(2) : Number(stock.Ask!= undefined ? stock.Ask : position.sellPrice).toFixed(2)}` }</p>
                </div>
            </div>
            <div className="d-flex justify-content-between align-items-center">
                <div className='position-margin'>
                    <p className='position-dateTime' style={{ fontWeight:'bold', color:'black'}}>{position.clientName} ({position.clientId})</p>
                </div>
                <div className="text-end position-margin">
                    <p className='position-dateTime'>
                        {/* {orderValue < parseFloat(userDetails.marginAvailable) && (
                            <input
                                className='position-cfbox'
                                type="checkbox"
                                name="rmargin[]"
                                id={position.orderId}
                                value={orderValue}
                                checked={position.cfRequested === "1"}
                                onChange={() => showToast("Under development")}
                            />
                        )} */}
                        CF Margin - { ((position.orderQuantity*position.orderPrice)/userDetails[position.exchange+'_delivery']).toFixed(2)}
                    </p>
                </div>
            </div>
        </div>
    );
}

export default PositionCardMLM;
